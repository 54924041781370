<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <div>
            <div>说明：</div>
            <div>1、学习函数导入位于./Model目录下的字典库和学习模型相关文件作为分类型，处理来自客户端的调用信息，输出json类型的对应标签。</div>
            <div>2、模型：指模型对应的路径，文件类型为学习服务输出的./Model的路径下的model文件。</div>
            <div>3、执行类型：指需要执行的服务类型。共“col_back_end”、”col_front_end“、"all_back_end”、"all_front_end"四种类型。其中“back_end“结尾的指自行传递待识别的数据集</div>
            <div>4、待识别数据：指需要识别的数据，以csvstring形式输入，当执行类型为“col_back”或“all_back_end”时有效。</div>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm m-t-20">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="模型" prop="model">
              <el-input v-model="ruleForm.model"></el-input>
            </el-form-item>
            <el-form-item label="执行类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择执行类型">
                <el-option label="col_front_end" value="col_front_end"></el-option>
                <el-option label="col_back_end" value="col_back_end"></el-option>
                 <el-option label="all_front_end" value="all_front_end"></el-option>
                  <el-option label="all_back_end" value="all_back_end"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据集ID" prop="id">
              <el-input v-model="ruleForm.id"></el-input>
            </el-form-item>
            <el-form-item label="数量限制" prop="num">
             <el-input-number v-model="ruleForm.num" :min="1" />
            </el-form-item>
            <el-form-item class="btn">
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
              <el-button @click="resetForm()">取消</el-button>
            </el-form-item>
          </el-form>
       </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'dataAdd',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
      return {
        ruleForm: {
          name: '',
          model:'',
          region: '',
          id:'',
          num:1
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          model: [
            { required: true, message: '请输入模型', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          id: [
            { required: true, message: '请输入id', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          num: [
            { required: true, message: '请输入id', trigger: 'blur' },
          ],
        }
      };
    },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
     submitForm(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$router.push({
                  name: 'qualityModel'
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          },
    resetForm() {
      this.$router.push({
        name: 'qualityModel'
      })
    },
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box>div{
    padding: 5px 10px;
    background-color: rgba(242, 242, 242, 1);
  }
  .box .btn :deep(.el-form-item__content) {
    text-align: center;
        margin: 0!important;
  }
</style>
